import React from "react"
import { Link } from "gatsby"
import { Container } from "reactstrap"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="Page Not found" />
    <Container>
      <h1>This page is not available</h1>
      <p>
        The link you followed may be broken, or the page may have been removed.
      </p>
      <Link to="/">Go back to Main page</Link>
    </Container>
  </>
)

export default NotFoundPage
